import * as React from "react";
import { Header, Container, Segment, Icon, Image, Grid, List, Message } from "semantic-ui-react";
import { withLayout } from "../components/Layout";
import Title from "../components/Title";

const pageTitle = "ITエンジニア向け勉強会の会場提供";

export const TechPlaceList = (
  <Container className="techplacelist">
    <List as="ul">
      <List.Item as="li">
        東京オフィス
        <List.List as="ul">
          <List.Item as="li"><a href="https://django.connpass.com/event/82148/" target="_blank" rel="noreferrer noopener">DjangoCongress JP 2018</a></List.Item>
          <List.Item as="li"><a href="https://connpass.com/event/107258/" target="_blank" rel="noreferrer noopener">プランクトンサミット</a></List.Item>
          <List.Item as="li"><a href="https://mackerelio.connpass.com/event/118995/" target="_blank" rel="noreferrer noopener">Mackerel Meetup #13 Tokyo</a></List.Item>
          <List.Item as="li"><a href="https://tokyo-2019.devrel.net/" target="_blank" rel="noreferrer noopener">DevRelCon Tokyo 2019</a></List.Item>
          <List.Item as="li"><a href="https://seleniumjp.connpass.com/event/123166/" target="_blank" rel="noreferrer noopener">SeleniumConf Tokyo 2019 事前勉強会</a></List.Item>
        </List.List>
      </List.Item>
      <List.Item as="li">
        大阪オフィス
        <List.List as="ul">
          <List.Item as="li"><a href="https://ng-kyoto.connpass.com/event/100685/" target="_blank" rel="noreferrer noopener">ng-kyoto Angular Meetup #8</a></List.Item>
          <List.Item as="li"><a href="https://kanjava.connpass.com/event/102370/" target="_blank" rel="noreferrer noopener">Payara&関ジャバイベント [大阪]</a></List.Item>
          <List.Item as="li"><a href="https://cssfriends.connpass.com/event/99503/" target="_blank" rel="noreferrer noopener">【2018年度】CSSフレンズ 第3回「CSSの仕事を効率的に！Sass/SCSSハンズオン」</a></List.Item>
        </List.List>
      </List.Item>
      <List.Item as="li">
        松山オフィス
        <List.List as="ul">
          <List.Item as="li"><a href="https://atnd.org/events/96211" target="_blank" rel="noreferrer noopener">プログラミング生放送勉強会 第52回＠サイボウズ株式会社 松山オフィス</a></List.Item>
        </List.List>
      </List.Item>
      <List.Item as="li">any more...</List.Item>
    </List>
  </Container>
);

const TechPlacePage: React.FC = () => {
  return (
    <Container fluid className="bg-sabo">
      <Container>
        <Title title={pageTitle} />
        <Segment vertical padded="very">
          <Grid columns="3" textAlign="center" divided relaxed stackable className="container">
            <Grid.Row>
              <Grid.Column>
                <Header icon as="h3">
                  <Icon name="star outline"></Icon>
                  複数拠点で同時開催可能
                </Header>
                <p>
                  各拠点を高性能なテレビ会議システムで接続して、総勢150名を超える勉強会を開催できます。
                </p>
              </Grid.Column>
              <Grid.Column>
                <Header icon as="h3">
                  <Icon name="map marker alternate"></Icon>
                  好アクセス
                </Header>
                <p>
                  どのオフィスも各地域の中心部にあり、仕事帰りでも自宅からでも立ち寄りやすい立地です。
                </p>
              </Grid.Column>
              <Grid.Column>
                <Header icon as="h3">
                  <Icon name="bullhorn"></Icon>
                  柔軟な開催日時
                </Header>
                <p>
                  平日夜または休日にご利用いただけます。週末は会場の空きがないこともしばしばあります。お早めにお申し込みください。
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Segment vertical padded="very">
          <Header as="h2" id="terms">
            <Header.Content>
              <Icon name="check" />
              ご利用条件
            </Header.Content>
          </Header>
          <List as="ul">
            <List.Item as="li">ITエンジニア向けの技術やデザインに関する勉強会・イベントであること</List.Item>
            <List.Item as="li">非営利目的の勉強会・イベントであること</List.Item>
            <List.Item as="li">会場都合による中止のリスクを許容いただけること</List.Item>
          </List>
          <p>
            内容については事前に弊社にて審査させていただき、ご利用に相応しいと判断するものに限らせていただきます。<br />
            また、会場の空き状況や立会の都合により、ご利用頂けないこともあります。
          </p>
          <p>
            会場提供プログラムは、サイボウズメンバーのボランティアによって成り立っています。<br />
            そのため、立会担当者の体調不良などの理由により、会場を提供できなくなる可能性があります。ご了承ください。
          </p>
        </Segment>

        <Segment vertical padded="very">
          <Header as="h2" id="facilities">
            <Header.Content>
              <Icon name="wifi" />
              ご利用いただける設備
            </Header.Content>
          </Header>
          <List as="ul">
            <List.Item as="li">
              Wifi
            </List.Item>
            <List.Item as="li">
              電源
              <List.List as="ul">
                <List.Item as="li">電源タップの貸出も可能です。</List.Item>
              </List.List>
            </List.Item>
            <List.Item as="li">
              プロジェクター
              <List.List as="ul">
                <List.Item as="li">解像度は 1920x1200 です。</List.Item>
                <List.Item as="li">接続端子はVGA、HDMI、Mini DisplayPort、USB Type-Cです。それぞれコネクタをご用意しています。
  </List.Item>
              </List.List>
            </List.Item>
            <List.Item as="li">
              マイク
              <List.List as="ul">
                <List.Item as="li">ハンドマイクとピンマイクをご用意しています。</List.Item>
              </List.List>
            </List.Item>
            <List.Item as="li">
              音声出力
              <List.List as="ul">
                <List.Item as="li">イヤホン端子接続での音声出力が可能です。HDMI接続での音声出力はできません。</List.Item>
              </List.List>
            </List.Item>
          </List>
          ※ 拠点によって異なる場合があります
        </Segment>

        <Segment vertical padded="very">
          <Header as="h2" id="faq">
            <Header.Content>
              <Icon name="question" />
              FAQ
            </Header.Content>
          </Header>
          <List as="ul">
            <List.Item as="li">
              イベント参加費の徴収は可能ですか？
              <List.List as="ul">
                <List.Item as="li">可能です。イベント運営費・懇親会費以外の費用を徴収される場合は事前にお知らせください。</List.Item>
              </List.List>
            </List.Item>
            <List.Item as="li">
              飲食は可能ですか？
              <List.List as="ul">
                <List.Item as="li">可能です。ケータリング搬入やアルコールもOKです</List.Item>
              </List.List>
            </List.Item>
          </List>
        </Segment>

        <Segment vertical padded="very">
          <Header as="h2" id="past">
            <Header.Content>
              <Icon name="compass outline" />
              これまでに開催された勉強会
            </Header.Content>
          </Header>
          抜粋になります。
          {TechPlaceList}
        </Segment>

        <Segment vertical padded="very">
          <Header as="h2" id="map">
            <Header.Content>
              <Icon name="map"/>
              Map
            </Header.Content>
          </Header>
          <Segment vertical padded>
            <Header as="h3">
              <Header.Content>東京オフィス</Header.Content>
            </Header>
            <List as="ul">
              <List.Item as="li">定員は80名です。</List.Item>
              <List.Item as="li">入館証が必要です。イベント開催確定後に主催者の方にメールでお送りします。</List.Item>
              <List.Item as="li">自動販売機はオフィス外の同フロアにあります。</List.Item>
              <List.Item as="li">ビル内は全館禁煙です。</List.Item>
            </List>
            <p>〒103-6027 東京都中央区日本橋2-7-1 東京日本橋タワー</p>
            <iframe title="東京オフィスの地図" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3240.803431956623!2d139.7720865!3d35.6818418!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188957b77f4145%3A0x50cb96950d8c152!2z44CSMTAzLTAwMjcg5p2x5Lqs6YO95Lit5aSu5Yy65pel5pys5qmL77yS5LiB55uu77yX4oiS77yRIOadseS6rOaXpeacrOapi-OCv-ODr-ODvA!5e0!3m2!1sja!2sjp!4v1476356264619" width="600" height="450"></iframe>
          </Segment>
          <Segment vertical padded>
            <Header as="h3">
              <Header.Content>大阪オフィス</Header.Content>
            </Header>
            <List as="ul">
              <List.Item as="li">定員は40名です。</List.Item>
              <List.Item as="li">入館証は不要です。ビルへの入館、オフィスへの入場は20時までです。</List.Item>
              <List.Item as="li">自動販売機はオフィス外の同フロアにあります。ビル15階にコンビニもあります。</List.Item>
              <List.Item as="li">ビル内は全館禁煙です。</List.Item>
            </List>
            <p>〒530-0017 大阪府大阪市北区角田町8番1号 大阪梅田ツインタワーズ・ノース 35階</p>
            <iframe title="大阪オフィスの地図" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1640.048469504431!2d135.4977230322889!3d34.70273482661614!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6000e692f16755c1%3A0x30a4d2a97295a0b!2z5qKF55Sw6Ziq5oCl44OT44OrIOOCquODleOCo-OCueOCv-ODr-ODvA!5e0!3m2!1sja!2sjp!4v1476356718005" width="600" height="450"></iframe>
          </Segment>
          <Segment vertical padded>
            <Header as="h3">
              <Header.Content>松山オフィス</Header.Content>
            </Header>
            <List as="ul">
              <List.Item as="li">定員は20名です。</List.Item>
              <List.Item as="li">入館証は不要です。</List.Item>
              <List.Item as="li">喫煙所はオフィス内にあります。</List.Item>
            </List>
            <p>〒790-0002 愛媛県松山市二番町3-7-12 QUALITA MATSUYAMA 3階</p>
            <iframe title="松山オフィスの地図" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1656.9706133955121!2d132.7690106!3d33.8396263!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa76b79b7c04245d!2z44K144Kk44Oc44Km44K6IOadvuWxseOCquODleOCo-OCuQ!5e0!3m2!1sja!2sus!4v1492392402989" width="600" height="450"></iframe>
          </Segment>
          <Segment vertical padded>
            <Header as="h3">
              <Header.Content>福岡オフィス</Header.Content>
            </Header>
            <List as="ul">
              <List.Item as="li">定員は30名です。</List.Item>
              <List.Item as="li">入館証は不要です。</List.Item>
              <List.Item as="li">自動販売機はオフィス外の同フロアにあります。</List.Item>
              <List.Item as="li">喫煙所はビルの2階にあります。</List.Item>
            </List>
            <p>〒812-0011 福岡県福岡市博多区博多駅前4-14-1 博多深見パークビルディング 12階</p>
            <iframe title="福岡オフィスの地図" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13294.766128001378!2d130.4193973!3d33.5873575!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x86d2db2177ab3079!2z44K144Kk44Oc44Km44K6IOemj-WyoeOCquODleOCo-OCuQ!5e0!3m2!1sja!2sjp!4v1632760267634!5m2!1sja!2sjp" width="600" height="450"></iframe>
          </Segment>
          <Segment vertical padded>
            <Header as="h3">
              <Header.Content>広島オフィス</Header.Content>
            </Header>
            <List as="ul">
              <List.Item as="li">定員は25名です。</List.Item>
              <List.Item as="li">入館証は不要です。ビルへの入館、オフィスへの入場は22時までです。</List.Item>
              <List.Item as="li">自動販売機はオフィス外の同フロアにあります。ビル1階にコンビニもあります。</List.Item>
              <List.Item as="li">喫煙所はビルの1階、6階、13階にあります。</List.Item>
            </List>
            <p>〒732-0822 広島県広島市南区松原町2-62 広島JPビルディング12階</p>
            <iframe title="広島オフィスの地図" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3292.1465822958226!2d132.473595!3d34.39762!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x355a9f2fedd44ac5%3A0xdabf81135a2efd7b!2z5bqD5bO2SlDjg5Pjg6vjg4fjgqPjg7PjgrA!5e0!3m2!1sja!2sjp!4v1690507302036!5m2!1sja!2sjp" width="600" height="450"></iframe>
          </Segment>
        </Segment>
      </Container>
    </Container>
  );
};

export default withLayout(TechPlacePage, pageTitle);
